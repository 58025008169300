<script>
  import { onMount } from "svelte";
  let name = "world";
  let now = new Date();
  let year = now.getFullYear();
  const blue = "#0300ad";
  const blueYellow = "#564A75";
  const blueRed = "#4F0274";
  const blueWhite = "#5755C8";
  const red = "#e70503";
  const redBlue = "#9B033C";
  const yellow = "#fdde06";
  const yellowBlue = "#AA943E";
  const black = "#050103";
  const white = "white";
  const whiteBlue = "#ABAAE4";
  const softGray = "#eaefe9";

  const SEC_RADIUS = 50;
  const MIN_RADIUS = 40;
  const HOUR_RADIUS = 23;

  const round = (value, decimals) => {
    return value === 0
      ? 0
      : Number(Math.round(value + "e" + decimals) + "e-" + decimals);
  };

  const toDegree = rad => (rad * Math.PI) / 180;

  const cpt = (r, deg) => {
    return { x: r * Math.cos(toDegree(deg)), y: r * Math.sin(toDegree(deg)) };
  };

  let hours = now.getHours();
  let minutes = now.getMinutes();
  let seconds = now.getSeconds();
  let secondRay = cpt(SEC_RADIUS, seconds * 6 - 90);
  let minuteRay = cpt(MIN_RADIUS, minutes * 6 - 90);
  let hourRay = cpt(HOUR_RADIUS, hours * 6 - 90);
  let secondX = secondRay.x;
  let secondY = secondRay.y;
  let minuteX = minuteRay.x;
  let minuteY = minuteRay.y;
  let hourX = hourRay.x;
  let hourY = hourRay.y;

  const tick = () => {
    now = new Date();
    hours = now.getHours();
    minutes = now.getMinutes();
    seconds = now.getSeconds();
    // console.log({ hours, minutes, seconds });
    secondRay = cpt(SEC_RADIUS, seconds * 6 - 90);
    minuteRay = cpt(MIN_RADIUS, minutes * 6 - 90);
    hourRay = cpt(HOUR_RADIUS, hours * 30 + minutes * 0.5 - 90);
    secondX = round(secondRay.x + 50, 2);
    secondY = round(secondRay.y + 50, 2);
    minuteX = round(minuteRay.x + 50, 2);
    minuteY = round(minuteRay.y + 50, 2);
    hourX = round(hourRay.x + 50, 2);
    hourY = round(hourRay.y + 50, 2);
  };

  onMount(() => {
    tick();
    const interval = setInterval(tick, 1000);

    return () => clearInterval(interval);
  });

  // $: console.log({ hourX, hourY }, hours);
</script>

<style>
  :root {
    --soft-gray: #eaefe9;
    --red: #e70503;
    --blue: #0300ad;
    --yellow: #fdde06;
    --black: #050103;
  }
  :global(body) {
    font-family: "Rubik Mono One", sans-serif;
    background: var(--soft-gray);
    color: var(--black);
    padding: 0;
    margin: 0;
    opacity: 1;
  }
  .page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 98vh;
    width: 100%;
  }
  h1,
  h2,
  h3,
  p {
    margin-top: 0;
  }
  .hero {
    background-color: var(--blue);
    overflow: hidden;
  }
  .hero .type {
    height: 98vh;
    width: 100vw;
    position: absolute;
    top: 0;
    overflow: hidden;
  }
  .hero .eye {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .hero .eye .eye_graphic {
    height: 55vh;
    width: 80vw;
    margin-top: -8vh;
  }
  .page p {
    font-family: Arial, sans-serif;
  }
  .work {
    background-color: var(--yellow);
    padding: 34px 0;
  }
  .work .props {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .work section {
    padding: 21px 0;
    width: 90%;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .work p {
    padding: 0 40px;
    line-height: 26px;
  }
  .work section > p:first-of-type {
    font-family: "Rubik Mono One", sans-serif;
    min-height: 55px;
    font-size: 13px;
    color: var(--blue);
  }
  .work section h3 {
    margin: 0 0 13px;
    padding: 0 40px;
  }
  .contact {
    padding: 21px 0;
    position: relative;
    overflow: hidden;
  }
  .contact p {
    padding: 0 40px;
    line-height: 26px;
    text-align: center;
  }
  .contact_link {
    display: block;
    color: var(--blue);
    text-align: center;
    font-size: 14px;
    letter-spacing: 1px;
  }
  .contact_link svg {
    filter: drop-shadow(1px 0px 3px rgba(0, 0, 0, 0.8));
    fill: #fff;
    display: block;
    margin: 1.5rem auto;
    max-width: 155px;
    max-height: 155px;
    transition: transform 0.2s;
  }
  .contact_link:hover svg {
    transform: scale(1.1);
  }
  .face {
    display: none;
    position: absolute;
    height: 110vh;
    top: 5vh;
    overflow: visible;
  }
  .face.face-left {
    left: -50vw;
  }
  .face.face-right {
    right: -50vw;
    transform: scaleX(-1);
  }
  .copyright {
    background: var(--black);
    color: var(--soft-gray);
    text-align: center;
    padding: 34px 21px 55px;
    font-size: 12px;
  }
  .copyright p {
    margin: 0;
    letter-spacing: 1px;
  }

  @media (min-width: 768px) {
    .work {
      padding: 55px 0;
    }
    .work .props {
      flex-direction: row;
      flex-grow: 1;
    }
    .work section {
      display: flex;
      min-width: 50%;
      max-width: 50%;
      align-items: center;
      justify-content: flex-start;
    }
    .work section p {
      text-align: center;
    }
  }
  @media (min-width: 920px) {
    .face {
      display: block;
      max-width: 30vw;
    }
    .face.face-left {
      left: -5vw;
      bottom: 0;
    }
    .face.face-right {
      right: -5vw;
    }
    .contact section {
      max-width: 40vw;
      text-align: center;
    }
  }
</style>

<svelte:head>
  <meta charset="utf-8" />
  <meta http-equiv="x-ua-compatible" content="ie=edge" />
  <meta name="copyright" content="Copyright {year} Clayton Huthwaite" />
  <link
    href="https://fonts.googleapis.com/css?family=Rubik+Mono+One&display=swap"
    rel="stylesheet" />
  <title>Clayton Huthwaite: Art</title>
  <meta name="description" content="Clayton Huthwaite does art and design" />
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <link rel="shortcut icon" href="favicon.gif" type="image/x-icon" />
  <meta name="description" content="Clayton Huthwaite does art and design" />

  <meta
    property="og:title"
    content="At Last! 1 Weird Site That Will 'Solve' Your Sundry 'Ailments'" />
  <meta
    property="og:description"
    content="This Web-Liniment treats Rheumatism, Neuralgia, Lame Back,
    Hyperthermia, Hypothermia, Hypoallergenics, Sciatica, Lame Duck, Lumbago,
    Bone Fever, Contracted Cords, Toothaches, Frost Bites, Sprains, Swellings,
    Abnormal Intelligence, Frost Bite, - in short, Everything it Ought to be
    Good For." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.claytonhuthwaite.com" />
  <meta
    property="og:image"
    content="https://www.claytonhuthwaite.com/og-image.png" />
  <meta property="fb:app_id" content="632406180280047" />

  <link rel="apple-touch-icon" href="apple-touch-icon.png" />
  <link
    rel="shortcut icon"
    href="https://www.claytonhuthwaite.com/favicon.gif"
    type="image/x-icon" />
  <link
    rel="sitemap"
    type="application/xml"
    title="Sitemap"
    href="sitemap.xml" />
</svelte:head>
<div class="page hero">
  <!-- <h1>Hello {name}!</h1> -->
  <div class="type">
    <svg
      class="typography"
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      opacity="0.9">
      <defs>
        <mask id="a-mask">
          <circle
            x="0"
            y="0"
            r="21"
            fill="white"
            transform="rotate(45, -15, 24)" />
        </mask>
        <mask id="t-mask">
          <line
            x1="-120"
            y1="-120"
            x2="120"
            y2="120"
            transform="translate(-17.5) rotate(45, 84, 123)"
            stroke="white"
            stroke-width="24" />
        </mask>
      </defs>

      <line
        x1="-140"
        y1="-140"
        x2="140"
        y2="140"
        transform="translate(-25)"
        stroke={red}
        opacity="0.75"
        stroke-width="24" />

      <circle x="0" y="0" r="21" fill={yellow} opacity="0.75" />

      <line
        x1="-9"
        y1="-60"
        x2="171"
        y2="120"
        stroke={black}
        opacity="0.34"
        stroke-width="1" />

      <line
        x1="122"
        y1="-20"
        x2="-18"
        y2="120"
        stroke={yellow}
        opacity="0.55"
        stroke-width="1" />

      <!-- text -->
      <text
        x="-15"
        y="24"
        fill={red}
        style="font-size:24px;"
        transform="rotate(-45, -15, 24)">
        A
      </text>
      <text
        x="-15"
        y="24"
        fill={yellow}
        style="font-size:24px;"
        transform="rotate(-45, -15, 24)"
        mask="url(#a-mask)">
        A
      </text>

      <text x="74" y="23" fill={blueWhite} transform="rotate(-45, 74, 23)">
        R
      </text>
      <text
        x="84"
        y="123"
        fill={red}
        style="font-size:55px;"
        transform="rotate(-45, 84, 123)">
        T
      </text>
      <text
        x="84"
        y="123"
        fill={blueRed}
        style="font-size:55px;"
        transform="rotate(-45, 84, 123)"
        mask="url(#t-mask)">
        T
      </text>
    </svg>
  </div>
  <div class="eye">
    <svg class="eye_graphic" width="100%" height="100%" viewBox="0 0 100 100">
      <defs>
        <mask id="sclera">
          <path
            fill={white}
            d="M 0,50 Q 25,25 50,25 Q 75,25 100,50 Q 75,75 50,75 Q 25,75 0,50 Z" />
        </mask>
        <mask id="iris">
          <circle cx="50" cy="50" r="20" fill={white} />
        </mask>
        <mask id="mini-white">
          <circle cx="82" cy="18" r="2.45" fill={white} />
        </mask>
      </defs>

      <!--sclera-->
      <path
        fill={white}
        d="M 0,50 Q 25,25 50,25 Q 75,25 100,50 Q 75,75 50,75 Q 25,75 0,50 Z" />

      <!-- iris -->
      <circle cx="50" cy="50" r="20" fill={blue} />

      <!--shapes-->
      <circle cx="4" cy="4" r="1.45" fill={whiteBlue} />
      <circle cx="24" cy="12" r="5" fill={blueYellow} />
      <circle cx="19" cy="14" r="2.65" fill={red} style="opacity:0.8;" />

      <circle cx="36" cy="36" r="4.45" fill={red} />
      <circle cx="36" cy="36" r="4.5" fill={blueRed} mask="url(#iris)" />

      <circle cx="64" cy="64" r="6.45" fill={yellow} />
      <circle cx="64" cy="64" r="6.5" fill={blueYellow} mask="url(#iris)" />

      <circle cx="14" cy="62" r="9.5" fill={yellowBlue} />
      <circle cx="14" cy="62" r="9.5" fill={yellow} mask="url(#sclera)" />
      <circle cx="25" cy="76" r="1.45" fill={whiteBlue} />

      <circle cx="78" cy="34" r="8.45" fill={redBlue} />
      <circle cx="78" cy="34" r="8.5" fill={red} mask="url(#sclera)" />

      <circle cx="82" cy="18" r="2.45" fill={white} />
      <circle cx="84" cy="16" r="3.5" fill={blueWhite} />
      <circle
        cx="84"
        cy="16"
        r="3.5"
        fill={whiteBlue}
        mask="url(#mini-white)" />

      <circle cx="75" cy="75" r="2.45" fill={redBlue} />
      <circle cx="82" cy="78" r="1.45" fill={yellow} />
      <circle cx="96" cy="96" r="1.45" fill={blueWhite} />

      <!--clock-->
      <!--second-->
      <line
        x1="50"
        y1="50"
        x2={secondX}
        y2={secondY}
        stroke={white}
        style="opacity:0.8;"
        stroke-width="1" />
      <!--minute-->
      <line
        x1="50"
        y1="50"
        x2={minuteX}
        y2={minuteY}
        stroke={yellow}
        style="opacity:0.8;"
        stroke-width="2" />
      <!--hour-->
      <line
        x1="50"
        y1="50"
        x2={hourX}
        y2={hourY}
        stroke={yellow}
        style="opacity:0.8;"
        stroke-width="5" />
      <!-- pupil -->
      <circle cx="50" cy="50" r="8" fill={black} />
      <circle cx="45" cy="45" r="3" fill={white} />

      <!--text-->
      <path
        fill="transparent"
        id="curve"
        d="M 0,50 Q 25,25 50,25 Q 75,25 100,50" />
      <text fill={white} style="font-size:7px;" dy="-4">
        <textPath xlink:href="#curve" text-anchor="middle" startOffset="50%">
          Clayton Huthwaite
        </textPath>
      </text>
    </svg>
  </div>
</div>
<div class="page work">
  <h2>ABOUT</h2>
  <p>I work in multiple media, whatever suits the project at hand.</p>
  <div class="props">
    <section>
      <h3>Fabrication</h3>
      <p>"I would build a wheel, a monster."</p>
      <p>
        I enjoy building things - from start to finish. I've added Sketchup to
        my repetoire, and have been steadily cultivating woodworking skills.
      </p>
    </section>
    <section>
      <h3>Drawing</h3>
      <p>"You draw me, you hard-hearted adamant..."</p>
      <p>
        Almost everything I create starts with thumbnail sketches, some branch
        out into comps and fully rendered designs. I also like traditional
        techniques, primarily focusing on ink drawings - preferably with a
        brush.
      </p>
    </section>
    <section>
      <h3>Painting</h3>
      <p>"Show me an angel and I'll paint one."</p>
      <p>
        I mostly work in acrylics (the larger the better), but I also dabble in
        watercolor occasionally.
      </p>
    </section>
    <section>
      <h3>Ceramics</h3>
      <p>"Go to, let us make brick, and burn them throughly."</p>
      <p>
        In middle school art class, I made a ceramic face. I might have ignored
        some important information about scoring and slipping pieces together...
        (Sorry Mrs. Damer!) It blew up in the kiln, leaving me with a hideous
        visage without a nose or cheek. I like to think that I've improved since
        then.
      </p>
    </section>
    <section>
      <h3>Writing</h3>
      <p>"Tomorrow I will write a story called ‘The Ashtray.'”</p>
      <p>
        Writing has been integral to my thought processes since I was a kid. I
        wrote a Redwall fanfic in fourth grade about a wizard hamster named
        Marvin. Naturally, English ended up being my major in college.
      </p>
    </section>
    <section>
      <h3>Code</h3>
      <p>"What's your vector, Victor?"</p>
      <p>
        Currently my interests include almost everything about front-end web
        development: Javascript (React, mostly), SVG (OMG), HTML &amp; CSS.
        Geometry has never been so fun.
      </p>
    </section>
  </div>
</div>
<div class="page contact">
  <h2>FOLLOW</h2>
  <svg viewBox="0 0 100 240" class="face face-left">
    <path
      d="M 0,0 H 75 Q 98,0 98,25 Q 96,45 98,60 Q 100,89 100,100 Q 100,105 98,105
      Q 90,105 78,105 Q 72.5,102.5 78,99.5 Q 92,98 92,102.5 Q 85,117 92,117 Q
      95,116 98,118 Q 100,123 98,128 Q 88,130 78,128 Q 70,136.5 78,145 Q 96,140
      98,152 Q 99,160 98,180 Q 98,210 100,240 H 0 Z"
      fill={softGray}
      stroke={black}
      stroke-alignment="inside"
      stroke-width="0.5" />
    <circle cx="55" cy="60" r="5" fill={red} />
    <circle cx="55" cy="60" r="2" fill={black} />
  </svg>
  <svg viewBox="0 0 100 240" class="face face-right">
    <path
      d="M 0,0 H 75 Q 98,0 98,25 Q 96,45 98,60 Q 100,89 100,100 Q 100,105 98,105
      Q 90,105 78,105 Q 72.5,102.5 78,99.5 Q 92,98 92,102.5 Q 85,117 92,117 Q
      95,116 98,118 Q 100,123 98,128 Q 88,130 78,128 Q 70,136.5 78,145 Q 96,140
      98,152 Q 99,160 98,180 Q 98,210 100,240 H 0 Z"
      fill={softGray}
      stroke={black}
      stroke-alignment="inside"
      stroke-width="0.5" />
    <circle cx="55" cy="60" r="5" fill={red} />
    <circle cx="55" cy="60" r="2" fill={black} />
  </svg>

  <section>
    <p>The best way to keep in contact is through my Instagram account.</p>
    <a
      class="contact_link"
      href="https://www.instagram.com/claytondraws/"
      target="_blank">
      Feel free to give me a follow.
      <svg
        aria-hidden="true"
        focusable="false"
        role="presentation"
        viewBox="0 0 512 512">
        <path
          d="M256 49.5c67.3 0 75.2.3 101.8 1.5 24.6 1.1 37.9 5.2 46.8 8.7 11.8
          4.6 20.2 10 29 18.8s14.3 17.2 18.8 29c3.4 8.9 7.6 22.2 8.7 46.8 1.2
          26.6 1.5 34.5 1.5 101.8s-.3 75.2-1.5 101.8c-1.1 24.6-5.2 37.9-8.7
          46.8-4.6 11.8-10 20.2-18.8 29s-17.2 14.3-29 18.8c-8.9 3.4-22.2
          7.6-46.8 8.7-26.6 1.2-34.5 1.5-101.8
          1.5s-75.2-.3-101.8-1.5c-24.6-1.1-37.9-5.2-46.8-8.7-11.8-4.6-20.2-10-29-18.8s-14.3-17.2-18.8-29c-3.4-8.9-7.6-22.2-8.7-46.8-1.2-26.6-1.5-34.5-1.5-101.8s.3-75.2
          1.5-101.8c1.1-24.6 5.2-37.9 8.7-46.8 4.6-11.8 10-20.2
          18.8-29s17.2-14.3 29-18.8c8.9-3.4 22.2-7.6 46.8-8.7 26.6-1.3 34.5-1.5
          101.8-1.5m0-45.4c-68.4 0-77 .3-103.9 1.5C125.3 6.8 107 11.1 91
          17.3c-16.6 6.4-30.6 15.1-44.6 29.1-14 14-22.6 28.1-29.1 44.6-6.2
          16-10.5 34.3-11.7 61.2C4.4 179 4.1 187.6 4.1 256s.3 77 1.5 103.9c1.2
          26.8 5.5 45.1 11.7 61.2 6.4 16.6 15.1 30.6 29.1 44.6 14 14 28.1 22.6
          44.6 29.1 16 6.2 34.3 10.5 61.2 11.7 26.9 1.2 35.4 1.5 103.9 1.5s77-.3
          103.9-1.5c26.8-1.2 45.1-5.5 61.2-11.7 16.6-6.4 30.6-15.1 44.6-29.1
          14-14 22.6-28.1 29.1-44.6 6.2-16 10.5-34.3 11.7-61.2 1.2-26.9 1.5-35.4
          1.5-103.9s-.3-77-1.5-103.9c-1.2-26.8-5.5-45.1-11.7-61.2-6.4-16.6-15.1-30.6-29.1-44.6-14-14-28.1-22.6-44.6-29.1-16-6.2-34.3-10.5-61.2-11.7-27-1.1-35.6-1.4-104-1.4z" />
        <path
          d="M256 126.6c-71.4 0-129.4 57.9-129.4 129.4s58 129.4 129.4 129.4
          129.4-58 129.4-129.4-58-129.4-129.4-129.4zm0 213.4c-46.4
          0-84-37.6-84-84s37.6-84 84-84 84 37.6 84 84-37.6 84-84 84z" />
        <circle cx="390.5" cy="121.5" r="30.2" />
      </svg>
    </a>
  </section>
</div>
<div class="copyright">
  <p>&copy; {year}, Clayton Huthwaite. All rights reserved.</p>
</div>
